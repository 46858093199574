// Colours
// Black
$pure-black: #000;
$black: #393939;
$almost-black: #0c1921;
$black-12: rgba(0, 0, 0, .12);
$green-67: rgba(211, 249, 80, 0.67);
$orange-67: rgba(249, 159, 80, 0.67);
$blue-67: rgba(80, 206, 249, 0.67);

// Blue
$azure: #009ee0;
$azure-two: #09a2e2;
$azure-three: #03a0e1;
$baby-blue: #a8e4ff;
$bluey-grey: #9aa3b3;
$carolina-blue: #94b9ff;
$marine-blue: #00335c;
$dark-grey-blue: #2b416b;
$dark-sky-blue: #1ea3e1;
$dark-sky-blue-two: #3ba6de;
$dark-sky-blue-three: #2aa4e0;
$light-blue: #7ed5f9;
$marine: #02345b;
$marine-blue: #00335c;
$marine-blue-two: #00345d;
$navy-blue: #001b50;
$ugly-blue: #32657c;

// Grey
$boulder: #7b7b7b;
$brownish-grey: #606060;
$brownish-grey-two: #606060;
$greyish: #a7a7a7;
$greyish-two: #a8a8a8;
$greyish-brown: #494949;
$greyish-brown-two: #434343;
$pale-grey: #f5f7fa;
$pinkish-grey: #cacaca;
$warm-grey: #7a7a7a;

// Pink
$hot-pink: #f10082;
$strong-pink: #f20e89;

// Green
$nasty-green: #78a243;
$turtle-green: #354422;

// White
$pure-white: #fff;
$white: #f4f4f4;
$white-two: #e4e4e4;
$white-three: #fff;
$white-four: #d5d5d5;
$white-five: #eee;
$white-six: #f0f0f0;

// Red
$boston-university-red: #c00;
$scarlet: #d10003;

// Yellow
$fluorescent-orange: #ffbe00;
$marigold: #ffbc00;

// Branding
$primary-color: $navy-blue;
$secondary-color: $marigold;
$tertiary-color: $greyish;

$bg-colors: (
  (pure-black, $pure-black,),
  (black, $black),
  (almost-black, $almost-black),
  (black-12,$black-12),
  (brownish-grey, $brownish-grey),
  (brownish-grey-two, $brownish-grey-two),
  (dark-sky-blue, $dark-sky-blue),
  (dark-sky-blue-two, $dark-sky-blue-two),
  (dark-sky-blue-three, $dark-sky-blue-three),
  (greyish, $greyish),
  (greyish-brown, $greyish-brown),
  (hot-pink, $hot-pink),
  (light-blue, $light-blue),
  (marigold, $marigold),
  (marine, $marine),
  (marine-blue, $marine-blue),
  (marine-blue-two, $marine-blue-two),
  (navy-blue, $navy-blue),
  (nasty-green, $nasty-green),
  (pinkish-grey, $pinkish-grey),
  (strong-pink, $strong-pink),
  (warm-grey, $warm-grey),
  (white, $white),
  (white-two, $white-two),
  (white-pure, $white-three),
  (white-five, $white-five),
  // user friendly themes
  (blue, $azure),
  (green, $nasty-green),
  (light-grey, $white),
  (navy, $marine-blue-two),
  (pink, $strong-pink),
  (primary-color,$primary-color),
  (secondary-color,$secondary-color),
  (tertiary-color,$tertiary-color)
);
