// Fonts
@font-face {
  font-family: 'Helvetica Neue';
  src: url('mysource_files/helveticaneue.woff') format('woff'),
    url('mysource_files/helveticaneue.ttf') format('truetype');
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('mysource_files/helveticaneue.eot') format('eot'),
    url('mysource_files/helveticaneue.ttf') format('truetype');
}

@font-face {
  font-family: 'Helvetica Neue Bold';
  src: url('mysource_files/helveticaneuebold.woff') format('woff'),
    url('mysource_files/helveticaneuebold.ttf') format('truetype');
}

@font-face {
  font-family: 'Helvetica Neue Bold';
  src: url('mysource_files/helveticaneuebold.eot') format('eot'),
    url('mysource_files/helveticaneuebold.ttf') format('truetype');
}

@font-face {
  font-family: 'Helvetica Neue Light';
  src: url('mysource_files/helveticaneuelight.woff') format('woff'),
    url('mysource_files/helveticaneuelight.ttf') format('truetype');
}

@font-face {
  font-family: 'Helvetica Neue Light';
  src: url('mysource_files/helveticaneuelight.eot') format('eot'),
    url('mysource_files/helveticaneuelight.ttf') format('truetype');
}

@font-face {
  font-family: 'Helvetica Neue Medium';
  src: url('mysource_files/helveticaneuemedium.woff') format('woff'),
    url('mysource_files/helveticaneuemedium.ttf') format('truetype');
}

@font-face {
  font-family: 'Helvetica Neue Medium';
  src: url('mysource_files/helveticaneuemedium.eot') format('eot'),
    url('mysource_files/helveticaneuemedium.ttf') format('truetype');
}
// Styles
p {
  color: $greyish-brown;
}

// Utilities
.u-font-primary {
  color: $primary-color !important;
}

.u-font--pure-white {
  color: $pure-white;
}
