/* Module: Breadcrumbs */
.breadcrumbs {
  color: $secondary-color;
  font-size: pxToRem(14);

  .has-subnav & {
    margin-top: pxToRem(36);
  }
}

.breadcrumbs__list {
  @extend %list-reset;
}

.breadcrumbs__item {
  display: inline-block;
}

.breadcrumbs__link {
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

.breadcrumbs__divider {
  @include sq-inline-block;
  color: $white;
}
