$hero-height-s: pxToRem(260);
$hero-height-s-parallax: pxToRem(640);
$hero-height-m: pxToRem(676);
$hero-height-m-parallax: pxToRem(1352);
$hero-height-l: pxToRem(670);
$hero-height-l-parallax: pxToRem(1340);
$hero-s-height-s: pxToRem(260);
$hero-s-height-l: pxToRem(220);
$hero-content-color: $white;

$hero-animi-transition: 1s cubic-bezier(.77, 0, .175, 1);
//$hero-animi-bg-color: lighten($primary-color, 80%);
$hero-animi-bg-color: lighten($pure-black, 30%);;
