.p-profile {
  border-bottom: pxToRem(2) solid $white;
  margin-bottom: pxToRem(45);
  padding-bottom: pxToRem(45);

  @media #{$mq-medium} {
    display: flex;
  }
}

.p-profile__content {
  color: $brownish-grey;

  ul {
    margin-bottom: pxToRem(14);
  }
}

.p-profile__img {
  margin-top: pxToRem(30);
  max-width: pxToRem(210);
  min-width: pxToRem(210);

  @media #{$mq-medium} {
    margin-left: pxToRem(30);
    margin-top: pxToRem(20);
  }

  img {
    width: pxToRem(210);
  }
}
