/* Module: S */
// Styles

.c-accordion {
  @include sq-transition(height $accordion-open-transition);
  border-left: $accordion-border-x;
  border-right: $accordion-border-x;
  border-bottom: $accordion-border-y;
  border-top: $accordion-border-y;

  .no-js & {
    height: auto;
  }
}

.c-accordion__item {
  &:not(:last-child) {
    border-bottom: $accordion-border;
  }

  .c-accordion:not(.js-is-tabs) & {
    position: relative;

    &::after {
      @include sq-transition(color $accordion-ui-transition,
                             transform $accordion-ui-transition,
                             right $accordion-ui-transition,
                             top $accordion-ui-transition);

      color: $accordion-title-color;
      content: '';
      display: block;
      line-height: $accordion-item-height;
      pointer-events: none;
      position: absolute;
      right: pxToRem(12);
      top: 0;
    }

    &.is-active {
      &::after {
        @include sq-transform(rotate(180deg));

        color: $accordion-active-title-color;
        //right: pxToRem(18);
        top: pxToRem(5);
      }
    }
  }
}

.c-accordion__title {
  @include sq-transition(background-color $accordion-ui-transition,
                         color $accordion-ui-transition,
                         opacity $accordion-ui-transition);

  background-color: $accordion-title-bg-color;
  border: $accordion-border-width solid transparent;
  color: $accordion-title-color;
  display: block;
  height: $accordion-item-height;
  line-height: $accordion-item-height;
  outline: none;
  padding: 0;
  text-align: left;
  text-decoration: none;
  width: 100%;

  .c-accordion__item.is-active & {
    //background-color: $accordion-active-title-bg-color;
    color: $accordion-active-title-color;
  }

  &:hover {
    background-color: $accordion-title-hover-bg-color;
  }

  h3 {
    @include sq-font-size($accordion-title-font-size);

    margin: 0;
    padding: 0 $accordion-padding-default;
  }
}

.c-accordion__panel {
  @include sq-font-size($accordion-font-size);
  @include sq-transition(max-height $accordion-open-transition);

  //background-color: $accordion-panel-bg-color;
  border-bottom: none;
  overflow: hidden;

  .js-enabled & {
    max-height: 0;
  }

  .c-accordion__item:not(.is-active) & {
    // override max-height value set from JS
    max-height: 0 !important;
  }
}

.c-accordion__panel__inner {
  padding: $accordion-panel-padding;
}

// Tab styles
.c-accordion.js-is-tabs {
  @include sq-flex-box();

  border: none;
  position: relative;

  .c-accordion__item {
    border: none;
    width: $accordion-tab-width;
    z-index: 0;

    &:not(:first-child) {
      border-left: $accordion-tab-margin solid transparent;
    }

    &.is-current {
      z-index: 2;

      .c-accordion__title {
        background-color: $accordion-active-tab-bg-color;
        border: $accordion-border;
        border-bottom: none;
        color: $accordion-active-tab-color;
        opacity: 1;
        margin-top: 0;
      }

      .c-accordion__panel {
        opacity: 1;
      }
    }
  }

  .c-accordion__title {
    @include sq-transition(background-color $accordion-ui-transition);

    background-color: $accordion-tab-bg-color;
    border-top-left-radius: $accordion-tab-border-radius;
    border-top-right-radius: $accordion-tab-border-radius;
    color: $accordion-tab-color;
    opacity: $accordion-tab-opacity;

    &:hover {
      background-color: $accordion-tab-hover-bg-color;
    }
  }

  .c-accordion__panel {
    @include sq-transition(opacity $accordion-panel-transition);

    border: $accordion-border;
    left: 0;
    max-height: initial;
    opacity: 0;
    position: absolute;
    margin-top: pxToRem(-1);
    width: 100%;
    z-index: -1;
  }
}
