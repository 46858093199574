/* Module: S */
// Mixins

// construct button
@mixin build-btn($bgColor: $btn-bg-color, $color: $btn-color) {
  background-color: $bgColor;
  border-color: $bgColor;
  color: $color;
  padding: $btn-padding-default;

  &:hover {
    background-color: saturate(lighten($bgColor, $btn-lighten-modifier), 1);
    border-color: saturate(lighten($bgColor, $btn-lighten-modifier), 1);
    text-decoration: none !important;
  }

  &.c-btn--border {
    &:hover {
      color: $bgColor;
    }
  }

  &.c-btn--border-inverse {
    background-color: $color;
    border-color: $bgColor;
    color: $bgColor;

    &:hover {
      background-color: $bgColor;
      color: $color;
    }
  }

  &.c-btn--3d {
    box-shadow: 0 pxToRem(4) saturate(darken($bgColor, $btn-darken-modifier), 1);
    position: relative;

    &:hover {
      background-color: $bgColor;
      border-color: $bgColor;
      box-shadow: 0 pxToRem(2) saturate(darken($bgColor, $btn-darken-modifier), 1);
      color: $color;
      top: pxToRem(2);
    }

    &.c-btn--3d.c-btn--border-inverse {
      &:hover {
        background-color: $color;
        color: $bgColor;
      }
    }

    &:active {
      box-shadow: 0 0 saturate(darken($bgColor, $btn-darken-modifier), 1);
      top: pxToRem(4);
    }
  }

  &.c-btn--disabled {
    cursor: default;

    &:hover {
      background-color: $color-disabled !important; // override hover settings from other button styles
      border-color: $color-disabled;
      color: $btn-color !important;
      top: auto;

      &.c-btn--border-inverse {
        background-color: $btn-color !important;
        color: $color-disabled !important;
      }

      &.c-btn--3d {
        box-shadow: 0 pxToRem(4) saturate(darken($bgColor, $btn-darken-modifier), 1);
      }
    }
  }
}


// Styles

.c-btn {
  @include build-btn();

  @include sq-transition(background-color $btn-transition, border-color $btn-transition, color $btn-transition);
  @include sq-font-size($btn-font-size-default);
  @include sq-border-radius($btn-border-radius);

  border: $btn-border-width solid transparent;
  display: inline-block;
  margin: $btn-margin-default;
  position: relative;
  text-decoration: none;
}

.c-btn--primary {
  @include build-btn($primary-color, $btn-color);
}

.c-btn--secondary {
  @include build-btn($secondary-color, $btn-color);
}

.c-btn--warning {
  @include build-btn($color-warning, $btn-color);
}

.c-btn--danger {
  @include build-btn($color-danger, $btn-color);
}

.c-btn--success {
  @include build-btn($color-success, $btn-color);
}

.c-btn--info {
  @include build-btn($color-info, $btn-color);
}

.c-btn--disabled {
  @include build-btn($color-disabled, $btn-color);
}

.c-btn--pinkish-grey {
  @include build-btn($pinkish-grey, $primary-color);
}

.c-btn--border {
  &:hover {
    background-color: $btn-color;
  }
}

.c-btn--lg {
  padding: $btn-padding-large;
}

.c-btn--sm {
  @include sq-font-size($btn-font-size-small);
  padding: $btn-padding-small;
}

.c-btn--round {
  @include sq-border-radius($btn-border-radius-round);
}

.c-btn--flat {
  @include sq-border-radius(0);
}
