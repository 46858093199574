/* Module: S */
// http://www.cssfilters.co/
.c-cssfilter {
  &:not(.is-loading) {
    position: relative;
  }

  .c-cssfilter__overlay {
    display: block;
    height: 100%;
    left: 0;
    opacity: 1;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 10;
  }

  .c-cssfilter__img {
    display: block;
  }

  // Variations
  &--dark:not(.is-broken) {
    .c-cssfilter__overlay {
      background: #000;
      opacity: .6;
    }
  }

  &--squiz:not(.is-broken) {
    .c-cssfilter__overlay {
      background: linear-gradient(to bottom, transparent 0, #000);
      opacity: .6;
    }
  }

  &--1977:not(.is-broken) {
    -webkit-filter: contrast(110%) brightness(110%) saturate(130%);
    filter: contrast(110%) brightness(110%) saturate(130%);

    .c-cssfilter__overlay {
      background: rgba(243, 106, 188, 0.3);
      mix-blend-mode: screen;
    }
  }

  &--aden:not(.is-broken) {
    -webkit-filter: contrast(90%) brightness(120%) saturate(85%) hue-rotate(20deg);
    filter: contrast(90%) brightness(120%) saturate(85%) hue-rotate(20deg);

    .c-cssfilter__overlay {
      background: -webkit-linear-gradient(to right, rgba(66, 10, 14, 0.2) 1, rgba(66, 10, 14, 0));
      background: linear-gradient(to right, rgba(66, 10, 14, 0.2) 1, rgba(66, 10, 14, 0));
    }
  }

  &--brooklyn:not(.is-broken) {
    -webkit-filter: contrast(90%) brightness(110%);
    filter: contrast(90%) brightness(110%);

    .c-cssfilter__overlay {
      background: -webkit-radial-gradient(50% 50%, circle closest-corner, rgba(168, 223, 193, 0.4) 1, rgba(183, 196, 200, 0.2));
      background: radial-gradient(50% 50%, circle closest-corner, rgba(168, 223, 193, 0.4) 1, rgba(183, 196, 200, 0.2));
      mix-blend-mode: overlay;
    }
  }

  &--earlybird:not(.is-broken) {
    -webkit-filter: contrast(90%) sepia(20%);
    filter: contrast(90%) sepia(20%);

    .c-cssfilter__overlay {
      background: -webkit-radial-gradient(50% 50%, circle closest-corner, rgba(208, 186, 142, 1) 20, rgba(29, 2, 16, 0.2));
      background: radial-gradient(50% 50%, circle closest-corner, rgba(208, 186, 142, 1) 20, rgba(29, 2, 16, 0.2));
      mix-blend-mode: overlay;
    }
  }

  &--gingham:not(.is-broken) {
    -webkit-filter: brightness(105%) hue-rotate(350deg);
    filter: brightness(105%) hue-rotate(350deg);

    .c-cssfilter__overlay {
      mix-blend-mode: darken;
      background: -webkit-linear-gradient(to right, rgba(66, 10, 14, 0.2) 1, rgba(0, 0, 0, 0));
      background: linear-gradient(to right, rgba(66, 10, 14, 0.2) 1, rgba(0, 0, 0, 0));
    }
  }

  &--hudson:not(.is-broken) {
    -webkit-filter: contrast(90%) brightness(120%) saturate(110%);
    filter: contrast(90%) brightness(120%) saturate(110%);

    .c-cssfilter__overlay {
      opacity: .5;
      background: -webkit-radial-gradient(50% 50%, circle closest-corner, rgba(255, 177, 166, 1) 50, rgba(52, 33, 52, 1));
      background: radial-gradient(50% 50%, circle closest-corner, rgba(255, 177, 166, 1) 50, rgba(52, 33, 52, 1));
      mix-blend-mode: multiply;
    }
  }

  &--inkwell:not(.is-broken) {
    -webkit-filter: contrast(110%) brightness(110%) sepia(30%) grayscale(100%);
    filter: contrast(110%) brightness(110%) sepia(30%) grayscale(100%);

    .c-cssfilter__overlay {
      background: rgba(0, 0, 0, 0);
    }
  }

  &--lofi:not(.is-broken) {
    -webkit-filter: contrast(150%) saturate(110%);
    filter: contrast(150%) saturate(110%);

    .c-cssfilter__overlay {
      background: -webkit-radial-gradient(50% 50%, circle closest-corner, rgba(0, 0, 0, 0) 70, rgba(34, 34, 34, 1));
      background: radial-gradient(50% 50%, circle closest-corner, rgba(0, 0, 0, 0) 70, rgba(34, 34, 34, 1));
      mix-blend-mode: multiply;
    }
  }

  &--perpetua:not(.is-broken) {
    // no styles needed

    .c-cssfilter__overlay {
      opacity: .5;
      background: -webkit-linear-gradient(to bottom, rgba(0, 91, 154, 1) 1, rgba(61, 193, 230, 0));
      background: linear-gradient(to bottom, rgba(0, 91, 154, 1) 1, rgba(61, 193, 230, 0));
      mix-blend-mode: soft-light;
    }
  }

  &--reyes:not(.is-broken) {
    -webkit-filter: contrast(85%) brightness(110%) saturate(75%) sepia(22%);
    filter: contrast(85%) brightness(110%) saturate(75%) sepia(22%);

    .c-cssfilter__overlay {
      opacity: .5;
      background: rgba(173, 205, 239, 1);
      mix-blend-mode: soft-light;
    }
  }

  &--toaster:not(.is-broken) {
    -webkit-filter: contrast(150%) brightness(90%) saturate(100%);
    filter: contrast(150%) brightness(90%) saturate(100%);

    .c-cssfilter__overlay {
      opacity: .5;
      background: rgb(173, 205, 239);
      mix-blend-mode: screen;
    }
  }

  &--walden:not(.is-broken) {
    -webkit-filter: brightness(110%) saturate(160%) sepia(30%) hue-rotate(350deg);
    filter: brightness(110%) saturate(160%) sepia(30%) hue-rotate(350deg);

    .c-cssfilter__overlay {
      opacity: .3;
      background: rgba(204, 68, 0, 1);
      mix-blend-mode: screen;
    }
  }

  &--xpro2:not(.is-broken) {
    -webkit-filter: sepia(30%) brightness(100%) contrast(100%) saturate(100%);
    filter: sepia(30%) brightness(100%) contrast(100%) saturate(100%);

    .c-cssfilter__overlay {
      background: rgb(204, 68, 0);
      mix-blend-mode: color-burn;
    }
  }
}
