.s-news-details {
  .o-layout {
    @media #{$mq-large} {
      padding-bottom: pxToRem(40);
    }
  }

  .o-content {
    @media #{$mq-large} {
      //margin-bottom: pxToRem(60);
    }
    ul {
      color: $greyish-brown;
    }
    h1 {
      font-size: pxToRem(35);
      line-height: pxToRem(48);

      + p {
        em {
          border-bottom: pxToRem(3) solid #f5f5f5;
          color: $greyish-two;
          display: block;
          font-size: pxToRem(14);
          font-style: normal;
          margin-bottom: pxToRem(25);
          padding-bottom: pxToRem(25);
        }
      }
    }
  }
}
