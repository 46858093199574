/* Module: S */
// Placehodlers
%sq-form-placeholder {
  color: $sq-form-field-placeholder-color;
}
%sq-form-placeholder--disabled {
  color: $sq-form-field-placeholder-disabled-color;
}

// Custom Styles

.sq-form-error {
  color: $sq-form-error-color;
  font-size: $sq-form-error-font-size;
  line-height: $sq-form-question-title-line-height;
  margin: $sq-form-error-margin;
}

.sq-form-field {
  background-color: $sq-form-field-background-color;
  border: $sq-form-field-border;
  box-sizing: $sq-form-field-box-sizing;
  color: $sq-form-field-color;
  font-size: $sq-form-field-font-size;
  height: $sq-form-field-height;
  outline: $sq-form-field-outline;
  padding: $sq-form-field-padding;
  resize: $sq-form-field-resize;
  width: $sq-form-field-full-width;

  @media screen and (min-width: $sq-form-field-breakpoint-xsmall) {
    width: $sq-form-field-width;
  }

  .no-object-fit & {
    padding: 0 pxToRem(14);
  }

  // Pseudo Elements
  &::-webkit-input-placeholder {
    @extend %sq-form-placeholder;
  }

  &::-moz-placeholder {
    @extend %sq-form-placeholder;
  }

  &:-ms-input-placeholder {
    @extend %sq-form-placeholder;
  }

  &:-moz-input-placeholder {
    @extend %sq-form-placeholder;
  }

  &[type=search] {
    box-sizing: $sq-form-field-box-sizing;
  }

  &:focus {
    border: $sq-form-field-border-focus;
  }

  &:disabled {
    border: $sq-form-field-border-disabled;

    &::-webkit-input-placeholder {
      @extend %sq-form-placeholder--disabled;
    }

    &::-moz-placeholder {
      @extend %sq-form-placeholder--disabled;
    }

    &:-ms-input-placeholder {
      @extend %sq-form-placeholder--disabled;
    }

    &:-moz-input-placeholder {
      @extend %sq-form-placeholder--disabled;
    }
  }

}

.no-object-fit textarea {
  padding: pxToRem(5) pxToRem(14) !important;
}

textarea {
  height: 100% !important;
}

// .sq-form-field[type="search"]{
//   height: $sq-form-field-search-height;
//   width: $sq-form-field-full-width;
//
//   @media screen and (min-width: $sq-form-field-breakpoint-xsmall) {
//     width: $sq-form-field-search-width;
//   }
// }

.sq-form-question {
  margin: $sq-form-question-margin;
}

.sq-form-question fieldset {
  padding: $sq-form-question-fieldset-padding;
  border: $sq-form-question-fieldset-border;
  width: $sq-form-field-full-width;

  @media screen and (min-width: $sq-form-field-breakpoint-xsmall) {
    width: $sq-form-field-width;
  }
}

.sq-form-question-tickbox-list, .sq-form-question-option-list {
  & ul {
    padding: 0;
    margin: $sq-form-field-checkbox-container-margin;
  }
  & ul li {
    list-style-type: none;
    color: $sq-form-field-checkbox-label-color;
    margin: $sq-form-field-checkbox-list-margin;
  }
}

[type="checkbox"]:not(:checked),
[type="checkbox"]:checked,
[type="radio"]:not(:checked),
[type="radio"]:checked  {
  position: absolute;
  left: -350%;
}

select::-ms-expand {
    display: none;
}

[type="checkbox"]:not(:checked) + label:before,
[type="checkbox"]:checked + label:before {
  content: ' ';
  display: inline-block;
  height: $sq-form-field-checkbox-height;
  margin: $sq-form-field-checkbox-margin;
  vertical-align: middle;
  width: $sq-form-field-checkbox-width;
}
[type="checkbox"]:not(:checked) + label:before {
    background: $sq-form-field-checkbox-background-unchecked;
    background-size: $sq-form-field-checkbox-background-size;
}
[type="checkbox"]:checked + label:before {
    background: $sq-form-field-checkbox-background-checked;
    background-size: $sq-form-field-checkbox-background-size;
}
[type="radio"]:not(:checked) + label:before,
[type="radio"]:checked + label:before {
  content: ' ';
  display: inline-block;
  height: $sq-form-field-radio-height;
  margin: $sq-form-field-radio-margin;
  vertical-align: middle;
  width: $sq-form-field-radio-width;
}
[type="radio"]:not(:checked) + label:before {
    background: $sq-form-field-radio-background-unchecked;
    background-size: $sq-form-field-radio-background-size;
}
[type="radio"]:checked + label:before {
    background: $sq-form-field-radio-background-checked;
    background-size: $sq-form-field-radio-background-size;
}

.sq-form-question-answer ul {
  @extend %list-reset;
}

.sq-form-question-answer select {
  background: $sq-form-field-select-background;
  width: $sq-form-field-full-width;
}

.sq-form-question-select, .sq-form-question-country {
  // Reset select styles
  & .sq-form-question-answer select {
    background: $sq-form-field-dropdown-background;
    background-size: $sq-form-field-dropdown-background-size;
    border: $sq-form-field-select-border;
    height: $sq-form-field-full-height;
    margin: $sq-form-field-select-margin;
    padding: $sq-form-field-select-padding;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;

    &:focus {
      border: $sq-form-field-select-border;
    }
  }
  // Custom style
  & .sq-form-question-answer {
    border: $sq-form-field-border;
    height: $sq-form-field-height;
    padding: $sq-form-field-select-padding;
    width: $sq-form-field-full-width;

    @media screen and (min-width: $sq-form-field-breakpoint-xsmall) {
      width: $sq-form-field-width;
    }

    &:focus {
      border: $sq-form-field-border-focus;
    }
  }
}
.sq-form-question-answer textarea {
  height: $sq-form-field-text-area-height;
  padding: $sq-form-field-text-area-padding;
}

.sq-form-question-error .sq-form-field {
  border: $sq-form-question-error-border;
}

.sq-form-question-title {
  color: $sq-form-question-title-color;
  display: $sq-form-question-title-display;
  font-size: $sq-form-question-title-font-size;
  margin: $sq-form-question-title-margin;
}

.sq-form-question-title abbr {
  color: $sq-form-required-field-color;
  border: $sq-form-required-field-border;
}

[type="submit"] {
  background-color: $sq-form-control-submit-background-color;
  border: $sq-form-control-submit-border;
  color: $sq-form-control-submit-color;
  font-weight: $font-bold;
  height: $sq-form-control-submit-height;
  width: $sq-form-control-submit-width;

  &:hover {
    background-color: $sq-form-control-submit-background-color-hover;
  }
}


