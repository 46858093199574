.p-footer {
  background-color: $primary-color;
  padding: pxToRem(35) 0;
}

.p-footer__container {
  @media #{$mq-medium} {
    align-content: stretch;
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.p-footer__social {
  align-content: stretch;
  align-items: center;
  color: $white;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  font-size: pxToRem(14);
  justify-content: space-between;

  @media #{$mq-medium} {
    justify-content: flex-start;
    align-self: center;
    width: 50%;
  }
}

.p-footer__social-item {
  @media #{$mq-medium} {
    margin-right: pxToRem(14);
  }
}

.p-footer__social-img {
  display: block;
  width: pxToRem(24);
}

.p-footer__search {
  margin-top: pxToRem(34);

  @media #{$mq-medium} {
    margin-top: 0;
    width: 50%;
  }
}

.p-footer__search-wrapper {
  align-items: center;
  background-color: $pure-white;
  border-radius: pxToRem(3);
  display: flex;
  justify-content: space-between;
  padding: pxToRem(5) pxToRem(10);

  @media #{$mq-large} {
    float: right;
    max-width: pxToRem(370);
    width: pxToRem(270);

    .p-footer & {
      width: pxToRem(365);
    }
  }
}

::-webkit-input-placeholder {
  color: $greyish;
  font-size: pxToRem(16);
}

::-moz-placeholder {
  color: $greyish;
  font-size: pxToRem(16);
}
 /* firefox 19+ */
:-ms-input-placeholder {
  color: $greyish;
  font-size: pxToRem(16);
}
 /* ie */
input:-moz-placeholder {
  color: $greyish;
  font-size: pxToRem(16);
}

.p-footer__search-input {
  background-color: transparent;
  border: 0;
  width: calc(100% - 2.125rem);

  @media #{$mq-large} {
    width: calc(100% - 1.5625rem);
  }
}

.p-footer__search-btn {
  background-color: transparent;
  background-image: url('mysource_files/ico-search.svg');
  background-position: right center;
  background-repeat: no-repeat;
  background-size: pxToRem(18);
  border: 0;
  height: pxToRem(19);
  width: pxToRem(18);
}

.p-footer__address {
  @media #{$mq-medium} {
    align-content: flex-start;
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    order: 1;
    width: 66.6666%;
  }

  @media #{$mq-large} {
    justify-content: flex-end;
    margin-top: pxToRem(36);
    width: 50%;
  }
}

.p-footer__address-item {
  @media #{$mq-medium} {
    width: 50%;
  }

  @media #{$mq-large} {
    width: auto;
  }

  &:first-of-type {
    margin-right: pxToRem(80);
  }
}

.p-footer__address-heading {
  color: $white;
  font-family: $font-helv-medium;
  font-size: pxToRem(16);
  line-height: pxToRem(20);
  margin-bottom: pxToRem(10);

  @media #{$mq-medium} {
    margin-top: 0;
  }
}

.p-footer__address-txt {
  color: $white;
  font-size: pxToRem(12);
}

.p-footer__menu {
  margin-bottom: pxToRem(36);
  margin-top: pxToRem(26);

  @media #{$mq-medium} {
    width: 100%;
  }

  @media #{$mq-large} {
    margin-bottom: pxToRem(10);
    margin-top: pxToRem(38);
    width: 50%;
  }
}

.p-footer__menu-list {
  @extend %list-reset;

  @media #{$mq-medium} {
    align-content: flex-start;
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .p-footer__menu--small & {
    @media #{$mq-large} {
      align-content: flex-start;
      align-items: flex-start;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
    }
  }
}

.p-footer__menu-item {
  line-height: 1;
  margin-bottom: pxToRem(22);

  @media #{$mq-medium} {
    width: 33.33333%;

    .p-footer__menu--small & {
      width: 100%;
    }
  }

  .p-footer__menu--small & {
    line-height: 1;
    margin-bottom: pxToRem(22);

    @media #{$mq-large} {
      float: left;
      margin-right: pxToRem(30);
      width: auto;
    }
  }
}

.p-footer__menu--small {
  @media #{$mq-medium} {
    width: 33.3333%;
  }

  @media #{$mq-large} {
    order: 2;
    width: 50%;
  }
}

.p-footer__menu-link {
  color: $carolina-blue;
  font-family: $font-helv-medium;
  font-size: pxToRem(14);
  // font-weight: 500;
  &:hover {
    text-decoration: underline;
  }

  .p-footer__menu--small & {
    font-size: pxToRem(12);
  }
}

.p-footer__copyright {
  color: $white;
  font-size: pxToRem(12);
  line-height: pxToRem(18);
  margin-top: pxToRem(26);
  width: 80%;

  @media #{$mq-medium} {
    order: 2;
    width: 100%;
  }

  @media #{$mq-large} {
    margin-top: 0;
    text-align: right;
    width: 50%;
  }
}

.no-object-fit .p-footer .p-footer__search-input {
  padding-bottom: pxToRem(5);
  padding-top: pxToRem(5);
}
