// Top Nav
.p-topnav {
  background-color: $primary-color;

  .o-container {
    padding-left: 0;
    padding-right: 0;

    @media #{$mq-large} {
      padding-right: pxToRem(20);
    }
  }
}

.p-topnav__container {
  align-content: stretch;
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.p-topnav__link {
  color: $white;
  display: block;
  font-family: $font-helv-medium;
  font-size: pxToRem(12);
  padding: pxToRem(14) pxToRem(20) pxToRem(12);
  text-transform: uppercase;
  transition: background-color .3s ease;

  @media #{$mq-medium} {
    padding: pxToRem(18) pxToRem(20) pxToRem(15);
    position: relative;

    .p-topnav__lang &:after {
      content: "";
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 6px solid #fff;
      position: absolute;
      top: 27px;
      right: 3px;
    }
  }

  &:hover {
    background-color: saturate(lighten($primary-color, $btn-lighten-modifier), 1);
    text-decoration: none;
  }

}

.p-topnav__lang {
  display: none;

  @media #{$mq-medium} {
    display: block;
  }
}

.p-topnav__report {
  @media #{$mq-medium} {
    margin-left: auto;
  }
}

.p-topnav__contact {
  background-color: $secondary-color;

  a {
    color: $primary-color;

    &:hover {
      background-color: saturate(lighten($secondary-color, $btn-lighten-modifier), 1);
    }
  }
}
