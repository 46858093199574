/* Module: S */
figure {
  max-width: 100%;
  figcaption {
    font-size: pxToRem(14);
  }
}

.c-img-caption {
  & + figcaption {
    color: $brownish-grey;
    font-size: pxToRem(14);
  }
}

p > figure:first-child {
  margin-top: pxToRem(5) !important;
}

p > img {
  margin-top: pxToRem(5) !important;
}

.pull-left {
  margin-right: pxToRem(56) !important;
}

.pull-right {
  margin-left: pxToRem(56) !important;
}
