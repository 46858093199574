// Custom CSS for accordion
.c-accordion {
  border-left: none;
  border-right: none;
}

.c-accordion__item {
  &::after {
    background-image: url('mysource_files/arrow-down-blue.svg');
    content: '';
    height: pxToRem(9);
    top: pxToRem(28) !important;
    width: pxToRem(16);
  }
}

.c-accordion__title {
  background-color: transparent;
  font-size: pxToRem(18);
  height: auto;
  padding: pxToRem(10) 0;
  &:hover {
    text-decoration: none;
  }
}
