.p-hero__content-bottom {
  position: relative;

  @media #{$mq-medium} {
    margin-top: pxToRem(-195);
  }

  &::before,
  &::after {
    background-color: $white;
    content: '';
    height: 100%;
    position: absolute;
    right: 0;
    top: pxToRem(25);
    width: 200%;
    z-index: -1;

    @media #{$mq-medium} {
      content: none;
    }

    .p-hero--small & {
      display: none;
    }
  }

  &::after {
    right: -100%;
  }

  @media #{$mq-medium} {
    width: 100%;
  }
}

.p-hero--small .p-info-panel__subheading {
  @media #{$mq-medium} {
    font-size: pxToRem(14) !important;
  }
}

.p-hero--small .p-info-panel__heading {
  @media #{$mq-medium} {
    font-size: pxToRem(16);
  }

  @media #{$mq-large} {
    font-size: pxToRem(18);
  }
}

.p-hero--small .p-info-panel.p-info-panel--dark {
  @media #{$mq-large} {
    padding: pxToRem(24) 0 !important;
  }
}

.p-hero--small .p-info-panel__content .p-info-panel__subheading {
  @media #{$mq-large} {
    width: 60% !important;
  }
}
