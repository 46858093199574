/*
--------------------
Custom Styles
--------------------
*/
//*-- Elements --*//
// @import 'elements/typography.scss';
// @import 'elements/links.scss';

//*-- Components --*//

//*-- Patterns --*//
@import 'patterns/profile.scss';
@import 'patterns/card-grid.scss';
@import 'patterns/photo-grid.scss';
@import 'patterns/quicklinks.scss';
@import 'patterns/contentslider.scss';
@import 'patterns/sharethis.scss';

//*-- Global Sections --*//
@import 'sections/contact-panel.scss';
@import 'sections/header.scss';
@import 'sections/navigation.scss';
@import 'sections/footer.scss';

//*-- Scope --*//
@import 'scope/home.scss';
@import 'scope/news-details.scss';
@import 'scope/search.scss';
