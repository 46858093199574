// Dark Theme
.p-info-panel--dark {
  background-color: $primary-color;
  border-top: pxToRem(1) solid lighten($primary-color, 10);
  color: $white;
  padding: $info-panel-margin;

  @media #{$mq-medium} {
    border-left: pxToRem(1) solid lighten($primary-color, 10);
    border-top: 0;
    padding: pxToRem(25) pxToRem(30);
  }

  .o-col__item:first-child & {
    border-left: 0;
    border-top: 0;
  }

  .p-info-panel__heading {
    color: $secondary-color !important;
  }

  .p-info-panel__heading-link {
    color: $secondary-color !important;

    svg {
      color: $secondary-color !important;
    }
  }

  .p-info-panel__subheading,
  .p-info-panel__info {
    color: $white;
  }
}

.p-info-panel__content {
  .p-hero & {
    @media #{$mq-medium} {
      flex-wrap: wrap;
    }

    @media #{$mq-large} {
      flex-wrap: nowrap;
    }

    .p-info-panel__subheading,
    .p-info-panel__heading {
      width: 100%;
    }
  }

  p,
  h3 {
    margin: 10px 0;
  }
}
