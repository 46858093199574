// Variables
$sq-purple: #6c6a99;

// Page level breakpoints
$mq-small: 'screen and (max-width: 37.438em)';    // max up to 599px
$mq-medium: 'screen and (min-width: 37.5em)';     // min from 600px
$mq-large: 'screen and (min-width: 60em)';        // min from 960px
$mq-large-extra: 'screen and (min-width: 75em)'; // min from 1200px

// Container settings
$default-container-max-width: pxToRem(1200);
$default-container-padding: pxToRem(20);
$default-col-layout-margin-bottom: pxToRem(14);
$default-debug-colour: $sq-purple;

// Susy
$susy: (
  columns: 12, // 12 by default
  gutters: .5
  //debug: (image: show)
);

$susy-layout: (
  columns: 16,
  //debug: (image: show)
);
