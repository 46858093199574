// Form Field Variables

// Sizes in px
$input-border-size: 1;
$input-height: 39;
$input-padding-top: 12;
$input-padding-right: 14;
$input-padding-bottom: 11;
$input-padding-left: 14;
$input-width: 370;
$text-area-height: 161;
$text-area-padding-top: 21;
$text-area-padding-right: 19;
$text-area-padding-bottom: 21;
$text-area-padding-left: 19;
$select-padding-left: 7;
$select-padding-right: 11;

// Breakpoints
$sq-form-field-breakpoint-xsmall: pxToRem(410);

$sq-form-control-submit-background-color: $primary-color;
$sq-form-control-submit-background-color-hover: #000e2a;
$sq-form-control-submit-border: none;
$sq-form-control-submit-color: white;
$sq-form-control-submit-height: pxToRem(49);
$sq-form-control-submit-width: pxToRem(103);

$sq-form-error-color: $scarlet;
$sq-form-error-font-size: pxToRem(12);
$sq-form-error-margin: pxToRem(7) 0 0 0;

$sq-form-field-background-color: white;
$sq-form-field-border: $greyish pxToRem($input-border-size) solid;
$sq-form-field-border-disabled: $white-five pxToRem($input-border-size) solid;
$sq-form-field-border-focus: $primary-color pxToRem($input-border-size) solid;
$sq-form-field-border-radius: 0;
$sq-form-field-box-sizing: border-box;
$sq-form-field-checkbox-background-checked: transparent url('mysource_files/tickbox-on@2x.png') no-repeat center;
$sq-form-field-checkbox-background-size: pxToRem(18) pxToRem(18);
$sq-form-field-checkbox-background-unchecked: transparent url('mysource_files/tickbox-off@2x.png') no-repeat center;
$sq-form-field-checkbox-container-margin: 0 0 pxToRem(36) 0;
$sq-form-field-checkbox-height: pxToRem(18);
$sq-form-field-checkbox-margin: 0 pxToRem(10) 0 0;
$sq-form-field-checkbox-label-color: $brownish-grey-two;
$sq-form-field-checkbox-list-margin: 0 0 pxToRem(16) 0;
$sq-form-field-checkbox-width: pxToRem(18);
$sq-form-field-color: $primary-color;
$sq-form-field-dropdown-background: white url('mysource_files/ico-arrow-blue-copy-2@2x.png') no-repeat right;
$sq-form-field-dropdown-background-size: pxToRem(14) pxToRem(7);
$sq-form-field-font-size: pxToRem(14);
$sq-form-field-full-height: 100%;
$sq-form-field-full-width: 100%;
$sq-form-field-line-height: pxToRem(14);
$sq-form-field-height: pxToRem($input-height);
$sq-form-field-outline: none;
$sq-form-field-padding: pxToRem($input-padding-top) pxToRem($input-padding-right) pxToRem($input-padding-bottom) pxToRem($input-padding-left);
$sq-form-field-placeholder-color: $greyish;
$sq-form-field-placeholder-disabled-color: $white-five;
$sq-form-field-radio-background-checked: transparent url('mysource_files/radio-on@2x.png') no-repeat center;
$sq-form-field-radio-background-size: pxToRem(20) pxToRem(20);
$sq-form-field-radio-background-unchecked: transparent url('mysource_files/radio-off@2x.png') no-repeat center;
$sq-form-field-radio-height: pxToRem(20);
$sq-form-field-radio-margin: 0 pxToRem(10) 0 0;
$sq-form-field-radio-label-color: $brownish-grey-two;
$sq-form-field-radio-list-margin: 0 0 pxToRem(16) 0;
$sq-form-field-radio-width: pxToRem(20);
$sq-form-field-resize: none;
$sq-form-field-select-background: transparent;
$sq-form-field-select-border: none;
$sq-form-field-select-margin: 0;
$sq-form-field-select-padding: 0 pxToRem($select-padding-right) 0 pxToRem($select-padding-left);
$sq-form-field-text-area-height: pxToRem($text-area-height);
$sq-form-field-text-area-padding: pxToRem($text-area-padding-top) pxToRem($text-area-padding-right) pxToRem($text-area-padding-bottom) pxToRem($text-area-padding-left);
$sq-form-field-width: pxToRem($input-width);

// Search input appears to require extra calculations due to sizing inconsistency
$sq-form-field-search-height: pxToRem($input-height - ($input-padding-top + $input-padding-bottom + $input-border-size*2));
$sq-form-field-search-width: pxToRem($input-width - ($input-padding-left + $input-padding-right + $input-border-size*2));

$sq-form-question-error-border: $scarlet pxToRem($input-border-size) solid;
$sq-form-question-margin:  0 0 pxToRem(36) 0;

$sq-form-required-field-color: $scarlet;
$sq-form-required-field-border: none;

$sq-form-question-fieldset-border: none;
$sq-form-question-fieldset-padding: 0;

$sq-form-question-title-color: $primary-color;
$sq-form-question-title-display: inline-block;
$sq-form-question-title-font-size: pxToRem(14);
$sq-form-question-title-line-height: pxToRem(14);
$sq-form-question-title-margin: 0 0 pxToRem(6) 0;
