/* Tables */
table {
  border-collapse: collapse;
  margin: .5em 0;
  width: 100%;
}

caption {
  @include sq-font-size(.8);
  font-style: italic;
}

th {
  background: $base-font-colour;
  color: invert($base-font-colour);
}

td {
  border: pxToRem(1) solid $base-font-colour;
}

/* Buttons */
.button {
  @include sq-transition(background-color .3s ease);
  background-color: $base-font-colour;
  border: 0;
  color: invert($base-font-colour);
  display: inline-block;
  padding: .5em 1em;

  &,
  &:visited {
    text-decoration: none;
  }

  &:focus,
  &:hover {
    background-color: darken($base-font-colour, 20%);
  }
}

.button--large {
  @include sq-font-size(1.5);
}

.button--small {
  @include sq-font-size(.8);
}

/* Quotes */
blockquote {
  margin: 2em 0;
  padding-left: 3em;
  position: relative;

  &::before {
    @include sq-font-size(5);
    color: $base-font-colour;
    content: '\201C';
    left: 0;
    line-height: 1em;
    position: absolute;
    top: 0;
  }
}

.pull-quote {
  border-top: pxToRem(1) solid $base-font-colour;
  margin: 1em;
  padding: 1em .5em 0;
}

/* Highlight */
.highlight {
  background: #ccc;
  @extend %inline-block;
  padding: .1em .5em;
}

/*Google translate bar*/
div.goog-te-gadget {
    display: none;
}