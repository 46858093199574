/* Module: S */
// Styles

#feedback-survey-trigger {
  visibility: hidden;
}

#lightbox-feedback-survey {
  @include sq-border-radius(pxToRem(8));

  background-color: $white;
  margin: 0 auto;
  max-width: 100%;
  position: relative;
  width: pxToRem(500);
  padding: pxToRem(25);
}

#feedback-survey-data ul li:before{
  background-image:none;
}

#feedback-survey-data ul {
    padding-left: 0;
    padding-bottom: 1.5rem;
}

#feedback-survey-data fieldset {
    border: none;
    padding: 0.75em 0;
}

#feedback-survey-data .sq-form-question-title {
    font-size: 1.25rem;
    padding-bottom: 1rem;
    padding-top:1rem;
}

#feedback-survey-data .sq-form-errors-message{
    color: #d10003;
}

#feedback-survey-data ul.tab-control__list li {
    display: inline-block;
    padding-right: 0.625rem;
}
