.p-photo-grid__list {
  @extend %list-reset;
  display: flex;
  flex-wrap: wrap;
}

.p-photo-grid__item {
  width: 100%;

  @media #{$mq-medium} {
    width: 50%;
  }

  @media #{$mq-large} {
    width: 33.333333%;
  }
}

.p-photo-grid__link {
  display: block;
  padding: pxToRem(10);
  text-decoration: none;
  transition: $transition-default;

  &:hover {
    background-color: $pinkish-grey;
    text-decoration: none;
  }
}

.p-photo-grid__box {
  align-items: center;
  display: flex;
}

.p-photo-grid__grid {
  &:first-of-type {
    margin-right: pxToRem(15);
  }
}

.p-photo-grid__img {
  display: block;
  max-width: inherit;
}
