/* Module: S */
// .p-social__heading {
//   line-height: 2.5rem;
//   margin: 2.5rem 0 0.9375rem 0;
// }

// a {
//   &.c-media-object__img.p-social__profile-img--small {
//     float: left;
//     margin: 0 0.625rem 0 0;
//     max-height: pxToRem(999);
//   }
// }

.c-media-object__body {
  overflow: hidden;
}

.p-social__list {
  @extend %list-reset;
  overflow: hidden;
}

.p-social__item {
  position: relative;
}

.p-social__box {
  padding: pxToRem(20);

  .p-social--fixed-height & {
    height: 100%;
  }

  .p-social--has-shadow & {
    @include sq-box-shadow(pxToRem(1) pxToRem(1) pxToRem(5) 0 rgba($greyish, 1));
  }
}

.p-social__link {
  vertical-align: middle;
  width: 100%;
}

.p-social__link-profile {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  display: block;
  height: 0;
  padding-bottom: 100%;
  width: 100%;
}

.p-social__img {
  display: block;
  height: pxToRem(200);
  object-fit: cover;
  object-position: center;
  width: 100%;

  .p-social--img-layout & {
    height: pxToRem(300);
  }
}

.p-social__title-box {
  padding-left: pxToRem(10);
}

.p-social__title {
  color: $social-title-colour;
  font-size: pxToRem(16);
  font-weight: $font-semi-bold;
  margin: 0 0 pxToRem(3);
}

.p-social__subtitle {
  color: $social-subtitle-colour;
  font-size: pxToRem(14);
  line-height: 1.2;
  margin: pxToRem(3) 0;
}

.p-social__timestamp {
  color: $social-timestamp-colour;
  display: block;
  font-size: pxToRem(12);
  line-height: 1.2;
}

.p-social__ctrl-list {
  @extend %list-reset;

  .p-social--img-layout & {
    @include sq-transition(transform $default-transition);
    bottom: pxToRem(-30);
    left: 0;
    margin: 0 auto;
    padding: pxToRem(2) pxToRem(10);
    position: absolute;
    right: 0;
    width: 100%;
  }

  &:hover,
  .p-social__item:hover & {
    .p-social--img-layout & {
      @include sq-transform(translateY(-1.875rem));
    }
  }
}

.p-social__ctrl-item {
  display: inline-block;

  .p-social--img-layout & {
    min-width: pxToRem(24);
    width: 10%;
  }
}

.p-social__ctrl-link {
  display: block;

  .p-social__instagram & {
    text-align: center;
  }
}

.p-social__icon {
  display: inline-block;
}

.p-social__content {
  color: $social-content-colour;
}
