a {

  // color: currentColor;
  
  .p-decision-tree__content-block p & {
    color: $link-color-main !important;
    &:hover {
      text-decoration: underline;
    }
  }

  &::before {
    background-color: currentColor ;
  }
  
  &.e-link--more-svg {
    @include sq-link--more-svg($secondary-color);
  }

  .p-info-panel &,
  .p-decision-tree &,
  .p-nav__submenu &,
  &.breadcrumbs__link,
  &.p-nav__menu-link {
    color: currentColor;
  }

  &.p-topnav__link,
  &.p-nav__menu-link,
  &.p-quicklinks__link,
  &.p-footer__menu-link,
  &.p-contact__list-link,
  &.p-social__link ,
  .p-info-panel &,
  .p-card &,
  .u-list-yellow &,
  .o-aside &,
  .p-decision-tree &,
  .p-nav__submenu-outer & {
    text-decoration: none;
  }
}
