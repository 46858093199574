.u-list-yellow {
  li {
    &::before {
      background-image: url('mysource_files/arrow-right-yellow.svg') !important;
    }
  }

  a:hover {
    text-decoration: underline;
  }
}
