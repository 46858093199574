/* Module: S */
/**
  * This module doesn't use any extra class to control the style
  * Breakpoint could be reset based on project
  **/
// Reset the list styles
// Global order/unorder list style
ol,
ul {
  @extend %list-reset;
  margin-bottom: pxToRem(55);
  padding-left: $list-align-size;

  li {
    margin-bottom: pxToRem(10);
    position: relative;

    &:last-child {
      margin-bottom: 0;
    }

    &::before {
      content: '';
      display: block;
      left: $list-align-size-pseudo;
      position: absolute;
    }

    // level 2 list
    ol,
    ul {
      margin-bottom: 0;
      margin-top: pxToRem(10);
      padding-left: pxToRem(35);

      // level 3 list
      ol,
      ul {
        padding-left: pxToRem(37);
      }
    }

   // level 2 list item
    li {
      &::before {
        left: $list-align-size-pseudo;
      }
    }
  }
}

// ol[type="i"] {
//   // @extend %list-reset;
//   list-style-type: lower-roman;
// }

//unordered list and sub list style
ul {
  li {
    position: relative;

    &::before {
      background-image: url($list-icon-img);
      background-position: 50% 50%;
      background-repeat: no-repeat;
      //background-size: cover;
      content: '';
      height: $list-icon-height;
      top: $list-icon-top;
      width: $list-icon-width;
    }
  }

  ol {
    & > li {
      &::before {
        background-image: none;
        top: 0;
      }
    }
  }
}

//ordered list and sub list count
ol {
  counter-reset: ol-lev-1;  // ordered list level 1 counter

  & > li {
    &::before {
      content: counter(ol-lev-1) '.';
      counter-increment: ol-lev-1;
    }
  }
}
ol[type="i"] {
  counter-reset: listStyle;  // ordered list level 1 counter

  & > li {
    &::before {
      content: counter(listStyle, lower-roman) '.';
      counter-increment: listStyle;
    }
  }
}
ol[type="a"] {
  counter-reset: listStyle;  // ordered list level 1 counter

  & > li {
    &::before {
      content: counter(listStyle, lower-alpha) '.';
      counter-increment: listStyle;
    }
  }
}

