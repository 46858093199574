// Default table theme
th,
td {
  padding: pxToRem(24) pxToRem(20);
  text-align: left;
  vertical-align: top;
}

th {
  background-color: transparent;
  color: $primary-color;
}

td {
  border: 0;
  color: currentColor;
}

table caption,
.e-tbl__caption {
  font-size: pxToRem(12);
  font-style: italic;
}

// default theme
th {
  background-color: $white;
}

tr {
  border-bottom: pxToRem(1) solid $white;
}

// Modifier
.e-tbl {
  // $bg-color is defined in squiz-module-a3-colours
  @each $theme, $bg-color in $bg-colors {
    &--#{$theme} {
      th {
        background-color: $bg-color;
      }

      tbody {
        tr {
          border-bottom: pxToRem(1) solid $bg-color;
        }
      }

      @if $theme == green or $theme == blue or $theme == navy {
        th {
          color: $pure-white;
        }
      }
    }
  }
}
