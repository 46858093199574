// Customise module
// Default theme
.c-carousel--default-theme {

  .c-carousel__box {
    margin: pxToRem(10);
    position: relative;

    img {
      height: pxToRem(300);
      width: 100%;
    }
  }

  .c-carousel__list:not(.js-carousel) {
    .c-carousel__box {
      margin: 0;
    }
  }

  .slick-arrow {
    @extend %sq-btn-reset;
    @include sq-transition(background-color $default-transition);
    outline: none;
    padding: 0;
    position: absolute;
    top: pxToRem(165);
    z-index: 1;

    &::before {
      background-color: transparent;
      color: $warm-grey;
      display: block;
      font-size: pxToRem(48);
      height: pxToRem(30);
      width: pxToRem(30);
      position: absolute;
      border-style: solid;
      border-width: pxToRem(2);
      border-color: $warm-grey;
    }
  }

  .slick-prev,
  .slick-next {
    background-color: transparent;
    border: 0;
    display: none !important;

    @media #{$mq-large} {
      display: block !important;
    }

    &::before {
      background-image: url('mysource_files/arrow-down-blue.svg');
      border: 0;
      content: '';
      height: pxToRem(16);
      position: absolute;
      width: pxToRem(28);
    }
  }

  .slick-prev {
    left: pxToRem(-28);
    margin-top: pxToRem(-20);

    &::before {
      transform: rotate(90deg);
    }
  }

  .slick-next {
    margin-top: pxToRem(-20);
    right: 0;

    &::before {
      transform: rotate(-90deg);
    }
  }

  .slick-arrow__txt {
    @extend %screen-hide;
  }

  .slick-dots {
    @extend %clearfix;
    @extend %list-reset;
    text-align: center;

    li {
      display: inline-block;
      font-size: 0;
      line-height: 1;
    }

    button {
      @extend %sq-btn-reset;
      @include sq-transition(background-color $default-transition);
      background-color: $greyish;
      border-radius: 50%;
      border-width: 0;
      font-size: pxToRem(0);
      height: pxToRem(14);
      line-height: 1;
      margin: pxToRem(2) pxToRem(7);
      padding: 0;
      width: pxToRem(14);
    }

    .slick-active {
      button {
        background-color: $navy-blue;
      }
    }
  }

  &.c-carousel--txt-inside {
    .c-carousel__content {
      color: $white;
    }

    .p-info-panel__time {
      color: $black;
    }
  }
}

// Modifier
.c-carousel--txt-inside {
  .c-carousel__content {
    left: 0;
    padding: pxToRem(16);
    position: absolute;
    top: 0;
  }

  .c-cssfilter .c-cssfilter__overlay {
    z-index: 0;
  }

  .slick-arrow {
    @include sq-transform(translateY(-50%));
    top: 50%;
  }
}

.c-carousel__img-wrapper {
  background-color: $navy-blue;
  height: pxToRem(270);
}

.c-carousel__heading {
  bottom: 0;
  color: $white;
  font-family: $font-helv-medium;
  font-size: pxToRem(18);
  line-height: pxToRem(26);
  margin: pxToRem(20) pxToRem(25);
  position: absolute;
  z-index: 10;

  span {
    transition: all .3s ease;

    &::after {
      background-image: url('mysource_files/arrow-right-yellow.svg');
      bottom: pxToRem(8);
      content: '';
      height: pxToRem(9);
      margin-left: pxToRem(10);
      position: absolute;
      transition: transform .3s ease;
      width: pxToRem(5);

      .c-carousel__img-wrapper:hover & {
        transform: translateX(.3125rem);
      }
    }
  }
}

.c-carousel--simple-theme {
  .c-carousel__heading {
    font-size: pxToRem(24);
    line-height: pxToRem(32);

    span::after {
      content: none;
    }

    small {
      display: block;
      font-family: $font-helv;
      font-size: pxToRem(16);
      line-height: pxToRem(22);
      margin-top: pxToRem(17);
    }
  }

  .c-carousel__img-wrapper:hover .c-carousel__heading span {
    color: $secondary-color;
  }
}

.c-carousel__box {
  .compat-object-fit-wrapper {
    height: pxToRem(270) !important;
  }
}
