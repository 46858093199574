.p-quicklinks__container {
  color: $white;
  padding-bottom: pxToRem(40);
  padding-top: pxToRem(40);
  
  @media #{$mq-medium} {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
}

.p-quicklinks__heading {
  color: $pure-white;
  font-family: $font-helv-medium;
  margin-top: 0;
}

.p-quicklinks__txt {
  color: $pure-white;
}

.p-quicklinks__list {
  margin-bottom: 0;
  margin-top: pxToRem(26);

  @media #{$mq-medium} {
    float: left;
    margin-top: pxToRem(13);
    padding-left: 0;
    width: 100%;
  }
}

.p-quicklinks__item {
  &:first-of-type {
    @media #{$mq-medium} {
      margin-right: pxToRem(20);
      width: 45%;
    }
  }

  &:last-of-type {
    @media #{$mq-medium} {
      width: 55%;
    }
  }
}

.p-quicklinks__list-item {
  @media #{$mq-medium} {
    float: left;
    padding-right: pxToRem(20);
    width: 50%;
  }

  &::before {
    background-image: url('mysource_files/arrow-right-yellow.svg');
  }
}

.p-quicklinks__link {
  color: $carolina-blue;
  font-size: pxToRem(18);
  font-weight: 600;

  &:hover {
    text-decoration: underline;
  }

  @media #{$mq-large} {
    font-weight: 500;
  }

  .is-windows.is-chrome &,
  .is-windows.is-firefox & {
    font-family: "Arial", sans-serif;
  }
}
