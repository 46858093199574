// HTML 5 Boilerplate Print Stylesheet
* {
  background: transparent !important;
  box-shadow: none !important;
  color: #000 !important; // Black prints faster: h5bp.com/s
  text-shadow: none !important;
}

a,
a:visited {
  text-decoration: underline;
}

a[href]::after {
  content: ' (' attr(href) ')';
}

abbr[title]::after {
  content: ' (' attr(title) ')';
}

// Don't show links for images, or javascript/internal links
.ir a::after,
a[href^='javascript:']::after,
a[href^='#']::after {
  content: '';
}

pre,
blockquote {
  border: pxToRem(1) solid #999;
  page-break-inside: avoid;
}

thead {
  display: table-header-group; // h5bp.com/t
}

tr,
img {
  page-break-inside: avoid;
}

img {
  max-width: 100% !important;
}

p,
h2,
h3 {
  orphans: 3;
  widows: 3;
}

h2,
h3 {
  page-break-after: avoid;
}
