.p-share {
  .o-content & {
    display: block;
    margin-bottom: pxToRem(40);

    @media #{$mq-large} {
      display: none;
    }
  }
  .o-aside & {
    display: none;

    @media #{$mq-large} {
      display: block;
      margin-bottom: pxToRem(80);
      margin-top: pxToRem(30);
    }
  }
}

.p-share__heading {
  color: $greyish-two;
  font-size: pxToRem(16);
  line-height: pxToRem(24);
  margin-top: pxToRem(5);
}

.p-share__list {
  @extend %list-reset;
  overflow: hidden;
}

.p-share__item {
  float: left;
  margin-right: pxToRem(12);
  width: pxToRem(31);
}

.p-share__img {
  height: pxToRem(30);
}
