/* Module: S */
/*profill object-fit style*/
.compat-object-fit {
  background-position: center center;
  background-size: cover;
  transition: transform 0.4s cubic-bezier(0.77, 0, 0.175, 1);

  img {
    opacity: 0;
  }

  a:hover & {
    transform: scale(1.2);
  }
}

.compat-object-fit-wrapper {
  max-width: 100%;
  overflow: hidden;
}

.c-objectfit {
  object-fit: cover;
  transition: transform 0.4s cubic-bezier(0.77, 0, 0.175, 1);

  a:hover & {
    transform: scale(1.2);
  }
}

a.c-objectfit__wrapper {
  overflow: hidden;
  display: inline-block;
}
