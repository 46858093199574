.p-contact {
  background-color: $white;
  display: none;

  @media #{$mq-large} {
    padding-bottom: pxToRem(60);
    padding-top: pxToRem(60);
  }
}

.p-contact__container {
  @media #{$mq-large} {
    align-content: stretch;
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
}

.p-contact__item {
  @media #{$mq-large} {
    flex-grow: 1;
    margin-left: pxToRem(45);
  }

  &:first-of-type {
    @media #{$mq-large} {
      margin-left: 0;
      max-width: pxToRem(380);
      min-width: pxToRem(380);
      width: pxToRem(380);
    }
  }
}

.p-contact__list-link {
  color: $primary-color;
  &:hover {
    text-decoration: underline;
  }
}

.p-contact__section {
  padding-top: pxToRem(1);
}

.p-contact__heading {
  color: $greyish-brown-two;
  line-height: pxToRem(30);
  margin-top: pxToRem(45);

  @media #{$mq-large} {
    margin-bottom: pxToRem(52);
  }
}

.p-contact__subheading {
  color: $greyish-brown-two;

  @media #{$mq-large} {
    margin-top: 0;
  }
}

.p-contact__list {
  @media #{$mq-large} {
    overflow: hidden;
    width: pxToRem(600);
  }
}

.p-contact__list-item {
  @media #{$mq-large} {
    float: left;
    width: pxToRem(290);
  }

  &::before {
    background-image: url('mysource_files/arrow-right-yellow.svg');
  }
}

.p-contact__panel {
  padding-bottom: pxToRem(26);

  @media #{$mq-large} {
    margin-top: pxToRem(80);
  }
}

.p-contact__panel-item {
  background-color: $pure-white;
  padding: pxToRem(20);

  .p-info-panel__subheading {
    font-size: pxToRem(14);
    line-height: pxToRem(24);
  }
}

.p-contact__cloase-wrapper {
  position: relative;
}

.p-contact__close {
  border: pxToRem(2) solid $greyish-brown-two;
  border-radius: 50%;
  display: block;
  height: pxToRem(30);
  position: absolute;
  right: pxToRem(20);
  top: pxToRem(18);
  transition: transform .3s ease;
  width: pxToRem(30);

  @media #{$mq-large} {
    right: 0;
    top: pxToRem(30);
  }

  &:hover {
    transform: rotate(90deg);
  }

  &::before,
  &::after {
    background-color: $greyish-brown-two;
    content: '';
    height: pxToRem(18);
    left: 50%;
    margin: pxToRem(-9) 0 0 pxToRem(-1);
    position: absolute;
    top: 50%;
    width: pxToRem(2);
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
}
