.c-section-nav__item {
  &:not(.is-current) {
    //display: none;

    @media #{$mq-large} {
      display: block;
    }
  }
}

.c-section-nav__btn {
  display: block;
}

.c-section-nav__icon {
  @media #{$mq-medium} {
    margin-top: pxToRem(-19);
  }

  @media #{$mq-large} {
    display: none;
  }
}
