/* Module: S */
.p-info-panel__wrapper {
  .o-col__item {
    align-content: stretch;
    align-items: stretch;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;

    // @media #{$mq-medium} {
    //   align-items: center;
    // }
  }
}

.p-info-panel {
  align-content: stretch;
  align-items: flex-start;
  color: $primary-color;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;

  h3,
  p {
    margin: $info-panel-margin 0;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.p-info-panel__icon {
  display: none;

  @media #{$mq-large} {
    display: block;
    margin-right: pxToRem(20);
  }
}

.p-info-panel__icon {
  padding-top: $info-panel-icon-padding-top;

  svg {
    font-size: $info-panel-icon-size;
  }
}

.p-info-panel__subheading {
  font-size: pxToRem(12);
  text-transform: uppercase;
}

.p-info-panel__time {
  background-color: $secondary-color;
  display: inline-block;
  font-size: pxToRem(12);
  padding: pxToRem(5);

  &:first-child {
    margin-top: $info-panel-margin;
  }
}

.p-info-panel__heading {
  font-family: $font-helv-bold;
  font-size: pxToRem(24);
  // font-weight: 600;
  letter-spacing: pxToRem(.5);
  line-height: 1.2;
}

.p-info-panel__heading-link {
  color: $primary-color !important;
  text-decoration: none;

  .e-link--more-svg-icon {
    bottom: $info-panel-link-icon-pos !important;
  }

  svg {
    color: $primary-color !important;
  }
}

.p-hero--more-svg {
  svg {
    @media #{$mq-large} {
      padding-bottom: 0 !important;
    }
  }
}

.p-info-panel__label {
  a {
    background-color: $tertiary-color;
    border-radius: pxToRem(20);
    color: $white;
    display: inline-block;
    font-size: pxToRem(12);
    margin-top: 0 !important;
    padding: pxToRem(5) pxToRem(15);
    text-decoration: none !important;
    transition: background-color .3s;

    &:hover {
      background-color: lighten($tertiary-color, 10%);
    }
  }
}

.p-info-panel__info {
  font-size: pxToRem(12);
}
