/* Module: S */
// Variables

$silver:                        #ccc;
$selectiveYellow:               #ffbc00;
$tundora:                       #434343;

$decision-tree-container-width: pxToRem(380);
$decision-tree-item-height:     pxToRem(42);
$decision-tree-arrow-color:     $selectiveYellow;


// Placeholders

%decision-tree-arrow {
  background-image: url(mysource_files/arrow-right-yellow.svg);
  background-repeat: no-repeat;
  background-size: 10px 10px;
  content: '';
  display: block;
  height: pxToRem(20);
  margin-top: pxToRem(-5);
  position: absolute;
  right: pxToRem(15);
  top: 50%;
  width: pxToRem(10);
}

// Styles

.p-decision-tree {
  max-width: 100%;
  overflow: hidden;
  width: 100%;
}

.p-decision-tree__btn--back {
  color: $primary-color;
  display: block;
  float: left;
  margin-bottom: pxToRem(15);
  padding-left: pxToRem(18);
  padding-right: pxToRem(12);
  position: relative;

  &::before {
    @extend %decision-tree-arrow;
    @include sq-transform(rotate(-180deg));

    background-color: transparent;
    left: 0;
    margin-top: 0;
    right: auto;
    top: pxToRem(-2);
  }
}

.p-decision-tree__inner {
  @extend %clearfix;

  white-space: nowrap;
  width: auto;
}

.p-decision-tree__list-container {
  display: inline-block;
  opacity: 1;
  vertical-align: top;
  width: $decision-tree-container-width;

  ul {
    @extend %list-reset;

    > li {
      background-color: $primary-color;
      color: $white;
      padding: 0 pxToRem(10);
      position: relative;
      width: 100%;

      a {
        @include sq-font-size(.8);

        display: block;
        line-height: $decision-tree-item-height;
        width: 100%;
        &:hover {
          text-decoration: underline;
        }
      }

      &::after {
        @extend %decision-tree-arrow;
      }
    }
  }
}

.p-decision-tree__list-top-bar {
  @extend %clearfix;
}

.p-decision-tree__list-title {
  color: $tundora;
  float: left;
  padding-left: pxToRem(12);
  position: relative;

  @media #{$mq-large} {
    max-width: pxToRem(260);
    white-space: normal;
  }

  &:before {
    background-color: $silver;
    content: '';
    display: block;
    height: pxToRem(30);
    position: absolute;
    left: 0;
    top: pxToRem(-3);
    width: pxToRem(1);
  }
}
