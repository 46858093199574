.p-card {
  ul {
    @extend %list-reset;
  }

  li {
    margin-bottom: pxToRem(25);
    position: relative;
    width: 100%;

    @media #{$mq-medium} {
      //margin-right: pxToRem(10);
      //width: calc(50% - .75rem);
      width: calc(50% - 10px);

      &:nth-child(even) {
        //margin-left: pxToRem(10);
        //margin-right: 0;
      }
    }

    @media #{$mq-large} {
      //margin-right: pxToRem(13);
      //width: calc(33.33333% - .8125rem);
      width: calc(33.3333% - 13px);

      &:nth-child(2n + 2) {
        //margin-left: pxToRem(6);
        //margin-right: pxToRem(6);
      }

      &:nth-child(3n) {
        //margin-left: pxToRem(15);
        //margin-right: 0;
      }
    }
  }

  a {
    background-color: $pure-white;
    color: $primary-color;
    display: block;
    font-size: pxToRem(18);
    line-height: pxToRem(28);
    order: 1;
    padding: pxToRem(20);
    transition: all .3s ease;
    width: 100%;

    @media #{$mq-large} {
      padding: pxToRem(40) pxToRem(45);
    }

    &:hover {
      background-color: $primary-color;
      color: $pure-white;
      text-decoration: none;

      span::after {
        transform: translateX(.3125rem);
      }
    }
  }

  .s-home__news & {
    a span {
      font-family: $font-helv-medium;

      @media #{$mq-medium} {
        font-size: pxToRem(24);
      }
    }
  }

  &.p-card--grey a {
    background-color: $white;

    &:hover {
      background-color: $primary-color;
      color: $white;
    }
  }

  span {
    display: block;
    margin-top: pxToRem(5);
    position: relative;

    &::after {
      background-image: url('mysource_files/arrow-right-yellow.svg');
      bottom: pxToRem(8);
      content: '';
      height: pxToRem(9);
      margin-left: pxToRem(10);
      position: absolute;
      transition: transform .3s ease;
      width: pxToRem(5);
    }

    .is-windows.is-firefox & {
      font-family: "Arial", sans-serif;
    }
  }

  &.p-card--large span {
    @media #{$mq-large} {
      font-family: $font-helv-medium;
      font-size: pxToRem(24);
    }
  }

  br {
    display: none;
  }

  em {
    background-color: $secondary-color;
    color: $dark-grey-blue;
    font-style: normal;
    display: inline-block;
    font-size: pxToRem(11);
    line-height: pxToRem(20);
    padding: 0 pxToRem(5);
  }
}

.p-card--img {
  li {
    background-color: $pure-black;
  }

  a {
    color: $pure-white;
    font-size: pxToRem(18);
  }
}

.p-card__btn-wrapper {
  margin-top: pxToRem(30);
  text-align: center;
}

.p-card__btn {
  color: $primary-color !important;
  font-size: pxToRem(14);
  font-weight: 600;
  padding: pxToRem(5) pxToRem(12);
  text-transform: uppercase;
}

// Jumpoff
.p-card--jumpoff {
  @media #{$mq-large} {
    margin-bottom: pxToRem(40);
    margin-top: pxToRem(30);
  }

  ul {
    align-content: flex-start;
    align-items: stretch;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  li {
    @media #{$mq-medium} {
      align-content: flex-start;
      align-items: stretch;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      margin-right: pxToRem(15);
      width: calc(33.3333% - .9375rem);
    }

    @media #{$mq-large} {
      width: calc(25% - .9375rem);
    }
  }

  a {
    align-items: flex-end;
    display: flex;
    font-size: pxToRem(22);
    padding: pxToRem(65) pxToRem(35) pxToRem(30);

    @media #{$mq-medium} {
      font-size: pxToRem(18);
      padding: pxToRem(85) pxToRem(30) pxToRem(30);
    }

    @media #{$mq-large} {
      font-size: pxToRem(22);
      padding: pxToRem(80) pxToRem(35) pxToRem(30);
    }
  }
}

.p-card__count {
  @media #{$mq-large} {
    font-size: pxToRem(20);
    margin: pxToRem(30) 0;
  }
}

.p-card__btn-icon {
  font-size: pxToRem(18);
  margin-left: pxToRem(4);
  position: relative;
  top: pxToRem(1);
  padding-bottom: 0;
}
