// Fonts
$font-helv: 'Helvetica Neue', sans-serif;
$font-helv-bold: 'Helvetica Neue Bold', sans-serif;
$font-helv-light: 'Helvetica Neue Light', sans-serif;
$font-helv-medium: 'Helvetica Neue Medium', sans-serif;
$font-franklin-gothic-urw: 'franklin-gothic-urw', sans-serif;
$font-light: 300;
$font-regular: 400;
$font-medium: 500; // franklin-gothic-urw
$font-semi-bold: 600;
$font-bold: 700;

$base-font-family: $font-helv;
