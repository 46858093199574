.c-btn[role="button"] {
  font-family: $font-helv-bold;
}

.c-btn--no-margin {
  margin: 0;
}

.c-btn--12 {
  font-size: pxToRem(12);
}

.c-btn--viewall {
  padding: 0 pxToRem(15);

  @media #{$mq-large} {
    font-family: $font-helv-bold;
    font-size: pxToRem(14);
    // font-weight: $font-semi-bold;
    margin-top: pxToRem(10);
    padding: pxToRem(6) pxToRem(15);
    text-transform: uppercase;
  }
}
