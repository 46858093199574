/* Module: S */
body {
  font-family: $base-font-family;
  -webkit-font-smoothing: antialiased;
  line-height: pxToRem(24);
}

h1,
.e-h1,
h2,
.e-h2,
h3,
.e-h3,
h4,
.e-h4,
h5,
.e-h5,
h6,
.e-h6 {
  color: $primary-color;
  font-family: $font-franklin-gothic-urw;
  font-weight: $font-medium;
  margin: pxToRem(30) 0 pxToRem(14);
}

h1,
.e-h1 {
  font-size: pxToRem(48);
  line-height: pxToRem(70);

  @media #{$mq-large} {
    font-size: pxToRem(62);
    line-height: pxToRem(70);
  }
}

h2,
.e-h2 {
  font-size: pxToRem(35);
  line-height: pxToRem(45);

  @media #{$mq-large} {
    font-size: pxToRem(54);
    line-height: pxToRem(58);
  }
}

h3,
.e-h3 {
  font-size: pxToRem(28);
  line-height: pxToRem(40);

  @media #{$mq-large} {
    font-size: pxToRem(35);
    line-height: pxToRem(40);
  }
}

h4,
.e-h4 {
  font-size: pxToRem(22);
  line-height: pxToRem(28);

  @media #{$mq-large} {
    font-size: pxToRem(22);
    line-height: pxToRem(30);
  }
}

h5,
.e-h5 {
  font-size: pxToRem(18);
  line-height: pxToRem(26);

  @media #{$mq-large} {
    font-size: pxToRem(18);
    line-height: pxToRem(26);
  }
}

h6,
.e-h6 {
  font-size: pxToRem(16);
  line-height: pxToRem(24);

  @media #{$mq-large} {
    font-size: pxToRem(16);
    line-height: pxToRem(24);
  }
}

p {
  margin: pxToRem(14) 0;
}

blockquote {
  margin: pxToRem(30) 0 pxToRem(30) 0;
  padding-left: pxToRem(28);

  &::before {
    background-color: $primary-color;
    content: '';
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: pxToRem(5);
  }
}

strong {
  font-weight: $font-semi-bold;
}

.highlight {
  background-color: $primary-color;
  color: $white;
  display: inline;
}

a[href^="tel:"] {
  cursor: default;
  &:hover {
    text-decoration: none;
  }
}