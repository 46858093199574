/* Module: S */
.p-hero {
  position: relative;

  // Due to Susy, we need to add -1px margin to it
  .p-info-panel__wrapper .o-col__item {
    margin-bottom: pxToRem(-1);
    margin-top: pxToRem(-1);
  }
}

.p-hero__inner {
  height: $hero-height-s;
  overflow: hidden;
  position: relative;

  @media #{$mq-medium} {
    height: $hero-height-m;
  }

  @media #{$mq-large} {
    height: $hero-height-l;
  }
}

.p-hero__img-wrapper {
  z-index: -1;

  .compat-object-fit-wrapper {
    width: 100% !important;
  }
}

.p-hero__img {
  height: $hero-height-s;
  width: 100%;

  @media #{$mq-medium} {
    .s-home & {
      height: $hero-height-m;
    }
  }

  @media #{$mq-large} {
    .s-home & {
      height: $hero-height-l;
    }
  }

  .parallax & {
    height: $hero-height-s-parallax;

    @media #{$mq-medium} {
      height: $hero-height-m-parallax;
    }

    @media #{$mq-large} {
      height: $hero-height-l-parallax;
    }
  }
}

.p-hero__content {
  color: $hero-content-color;
  max-width: pxToRem(690);
  position: absolute;
  top: pxToRem(60);
}

.p-hero__heading {
  color: $white;
  font-size: pxToRem(28);
  font-weight: 600;
  line-height: 1.2;
  margin-top: 0;

  @media #{$mq-medium} {
    font-size: pxToRem(62);
    font-weight: 500;
    margin-top: pxToRem(82);
  }
}

.p-hero--more-svg {
  font-size: pxToRem(18);
  // font-weight: 600;

  .e-link--more-svg-icon {
    bottom: pxToRem(3) !important;
  }
}

.p-hero__content-bottom {
  margin-top: pxToRem(-25);

  @media #{$mq-medium} {
    bottom: pxToRem(65);
    margin-top: 0;
    max-width: pxToRem(1200);
    position: absolute;
    width: calc(100% - 40px);
  }

  @media #{$mq-large} {
    width: 100%;
  }
}

.p-hero__container {
  @media #{$mq-large} {
    position: relative;
  }
}

.p-info-panel {
  flex-grow: 1;
}

// Small
.p-hero--small {
  .p-hero__inner {
    height: $hero-s-height-s;

    @media #{$mq-large} {
      height: $hero-s-height-s;
    }
  }

  .p-info-panel--dark {
    background-color: transparent;
  }

  .p-info-panel__wrapper .o-col__item {
    margin: 0;
  }

  .p-hero__container {
    background-color: $primary-color;
    padding: 0;
  }

  .p-hero__content {
    bottom: pxToRem(25);
    max-width: none;
    top: auto;
  }

  .p-hero__intro {
    color: $secondary-color;
    font-size: pxToRem(14);
  }

  .p-hero__heading {
    font-size: pxToRem(28);
    font-weight: 500;
    margin-top: 0;

    @media #{$mq-large} {
      font-size: pxToRem(54);
    }
  }

  .p-hero__content-bottom {
    margin-top: 0;
    position: static;
  }

  // Info panel
  .p-info-panel--dark {
    border: 0 !important;
    padding: pxToRem(10) 0;

    @media #{$mq-large} {
      padding-bottom: pxToRem(24) !important;
      padding-top: pxToRem(24) !important;
    }
  }

  .o-col__item:first-of-type .p-info-panel--dark {
    padding-top: pxToRem(15);
  }

  .o-col__item:nth-child(2) .p-info-panel--dark {
    padding: 0;

    @media #{$mq-large} {
      padding: 0 pxToRem(20);
    }
  }

  .o-col__item:last-of-type .p-info-panel--dark {
    padding-bottom: pxToRem(15);
  }

  .p-info-panel__content {
    align-content: stretch;
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    width: 100%;

    @media #{$mq-medium} {
      align-items: center;
    }
  }

  .p-info-panel__subheading {
    line-height: 1.4;
    margin: 0 pxToRem(10) 0 0;
    width: 40%;

    @media #{$mq-large} {
      width: auto;
    }
  }

  .p-info-panel__heading {
    font-size: pxToRem(14);
    margin: 0;
  }
}

// Animation
.js .p-hero--animation {
  // init
  &::before {
    background-color: $hero-animi-bg-color;
    content: '';
    height: 100%;
    position: absolute;
    transition: all .3s ease;
    width: 100%;
  }

  .p-hero__intro,
  .p-hero__heading,
  .p-hero__more,
  .p-info-panel__wrapper .o-col__item {
    opacity: 0;
    transform: translateY(2.5rem);
    transition: all $hero-animi-transition;
    visibility: hidden;
  }

  // image loaded
  &.is-hero-image-loaded {
    &::before {
      opacity: 0;
      visibility: hidden;
    }

    .p-hero__intro,
    .p-hero__heading,
    .p-hero__more,
    .p-info-panel__wrapper .o-col__item {
      opacity: 1;
      transform: translateY(0);
      visibility: visible;
    }

    .p-hero__intro {
      transition-delay: 0;
    }

    .p-hero__heading {
      transition-delay: 0;
    }

    &.p-hero__small .p-hero__heading {
      transition-delay: .3s;
    }

    .p-hero__more {
      transition-delay: .3s;
    }

    .p-info-panel__wrapper .o-col__item:nth-child(1) {
      transition-delay: .8s;
    }

    .p-info-panel__wrapper .o-col__item:nth-child(2) {
      transition-delay: 1s;
    }

    .p-info-panel__wrapper .o-col__item:nth-child(3) {
      transition-delay: 1.2s;
    }
  }
}

.p-hero--small .p-info-panel.p-info-panel--dark {
  @media #{$mq-medium} {
    padding: pxToRem(16) 0 !important;
  }
}
