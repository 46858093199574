.s-home__news {
  padding-top: pxToRem(45);
  padding-bottom: 2rem; 

  @media #{$mq-large} {
    padding: pxToRem(78);
  }

  .o-col {
    flex-wrap: nowrap;
  }
}

.s-home__heading {
  line-height: .8;
  margin-top: 0;
}

.s-home__find {
  @media #{$mq-large} {
    margin-top: pxToRem(40);
  }

  h3 {
    font-size: pxToRem(35);
  }

  .p-home__find-search {
    margin: pxToRem(30) 0;

    .p-footer__search-input {
      padding: pxToRem(10) pxToRem(5);
    }
  }

  .p-footer__search-wrapper {
    background-color: $white-six;

    @media #{$mq-large} {
      margin-bottom: pxToRem(30);
      max-width: 100%;
      width: 100%;
    }
  }

  ul {
    margin-bottom: 0;
  }

  .p-home__find-list {
    li {
      @media #{$mq-large} {
        float: left;
        width: 50%;
      }
    }
  }

  a {
    color: $primary-color;
  }
}

.s-home .o-content {
  padding-bottom: 0;
}

#search_page_464398 input {
  line-height: normal;
}
