.p-social--default-theme {
  .p-social__ctrl-link {
    color: $white;

    &:focus,
    &:hover {
      .p-social__icon--like {
        color: $hot-pink;
      }

      .p-social__icon--share {
        color: $marine;
      }
    }
  }

  .p-social__icon {
    @include sq-transition(color $default-transition);

    .p-social__ctrl-link:focus &,
    .p-social__ctrl-link:hover & {
      &.p-social__icon--like {
        color: $hot-pink;
      }

      &.p-social__icon--share {
        color: $marine;
      }
    }
  }
}

.p-social--img-layout {
  .p-social__img {
    height: pxToRem(130);
    margin-bottom: pxToRem(14);
    padding-left: pxToRem(7);

    @media #{$mq-medium} {
      height: pxToRem(180);
      margin-bottom: 0;
      padding-left: 0;
    }

    @media #{$mq-large} {
      height: pxToRem(236);
    }
  }

  li:nth-child(odd) .p-social__img {
    padding-left: 0;
    padding-right: pxToRem(7);

    @media #{$mq-medium} {
      padding-right: 0;
    }
  }

  li:last-of-type {
    @media #{$mq-medium} {
      display: none;
    }

    @media #{$mq-large} {
      display: block;
    }
  }
}

.p-social__item {
  @media #{$mq-medium} {
    max-width: 25%;
    min-width: 25%;
  }

  @media #{$mq-large} {
    max-width: 20%;
    min-width: 20%;
  }
}

.p-socialmedia {
  margin-top: pxToRem(60);

  &.u-no-margin-top {
    @media #{$mq-large} {
      margin-bottom: pxToRem(60);
      margin-top: pxToRem(40) !important;
    }
  }
}

.p-socialmedia__list {
  @extend %list-reset;
  text-align: center;
}

.p-socialmedia__item {
  display: inline-block;
  margin-bottom: 0;
  margin-right: pxToRem(-4);
}

.p-socialmedia__link {
  color: $greyish-brown-two;
  display: block;
  font-family: $font-franklin-gothic-urw;
  font-size: pxToRem(16);
  padding: pxToRem(15) pxToRem(23);
  text-decoration: none;
  transition: all .3s ease;

  @media #{$mq-medium} {
    font-size: pxToRem(20);
    padding: pxToRem(24) pxToRem(35);
  }

  &:hover {
    background-color: $white-five;
    color: $navy-blue;
    text-decoration: none;
  }

  .is-tab-active & {
    background-color: $white-five;
    color: $navy-blue;
  }
}

.p-socialmedia__content {
  background-color: $white-five;

  .p-socialmedia__transparent & {
    background-color: transparent;
  }
}

.p-socialmedia__section {
  display: none;

  &.is-tab-active {
    display: block;
  }
}

.p-socialmedia__heading {
  text-align: center;
}

.p-socialmedia__inner {
  padding: pxToRem(40) 0 pxToRem(20);
}

.p-socialmedia__logo-img {
  width: pxToRem(24);

  @media #{$mq-medium} {
    width: pxToRem(30);
  }

  .p-socialmedia__logo--large & {
    width: pxToRem(40);
  }
}

.p-socialmedia__follow {
  color: $primary-color;
  font-size: pxToRem(14);
  margin-bottom: pxToRem(30);
  margin-top: pxToRem(0);

  a {
    font-weight: 600;
  }

  @media #{$mq-medium} {
    font-size: pxToRem(18);
    margin-top: pxToRem(8);
  }
}

.p-socialmedia__btn {
  color: $white;
  font-size: pxToRem(14);
  font-weight: 600;
  margin-top: pxToRem(20);
  padding: pxToRem(5) pxToRem(10);
  text-transform: uppercase;

  @media #{$mq-medium} {
    margin: pxToRem(50) 0 pxToRem(30);
  }
}

.p-social__box-top {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
}

.p-social__title-box {
  flex-grow: 1;
  margin-bottom: pxToRem(20);
  padding-left: pxToRem(10);
}

.p-social__profile-box {
  min-width: pxToRem(50);
}

.p-social__title {
  line-height: 1;
}

.p-social__link-read-more {
  color: $primary-color;
  font-weight: 600;
  &:hover {
    text-decoration: underline;
  }
}

.p-socialmedia--fb,
.p-socialmedia--twitter {
  .p-social__item {
    @media #{$mq-medium} {
      max-width: 50%;
      width: 50% !important;
    }

    @media #{$mq-large} {
      margin-right: 0 !important;
      max-width: 25%;
      width: 25% !important;
    }
  }

  .p-social__box {
    @media #{$mq-medium} {
      &:nth-child(odd) {
        margin-right: pxToRem(10);
      }

      &:nth-child(even) {
        margin-left: pxToRem(10);
      }
    }

    @media #{$mq-large} {
      margin-left: pxToRem(10);
      margin-left: pxToRem(10);
    }
  }

  .compat-object-fit-wrapper {
    height: pxToRem(200) !important;
  }
}

.p-socialmedia__title {
  margin: pxToRem(10) 0;
}

.p-socialmedia__follow--large {
  font-size: pxToRem(18);
}
