body {
  background-color: #fff;
}

.o-page-wrapper {
  max-width: 100vw;
  overflow: hidden;
  position: relative;

  .is-menu-open & {
    max-height: 100vh;
  }
}

.o-page-container {
  transition: transform .4s cubic-bezier(.77, 0, .175, 1);

  .is-menu-open & {
    transform: translateX(-100%);
  }
}

.o-layout {
  padding-bottom: pxToRem(0);
  //padding-bottom: pxToRem(90);
  padding-top: pxToRem(26);

  @media #{$mq-large} {
    //padding-bottom: pxToRem(184);
    padding-top: pxToRem(60);
  }

  .s-home & {
    padding-top: 0;
  }

  .s-news-details & {
    @media #{$mq-large} {
      padding-top: pxToRem(40);
    }
  }
}

.o-content {
  padding-bottom: pxToRem(60);

  @media #{$mq-large} {
    padding-bottom: pxToRem(100);
  }
}

.o-aside {
  min-height: pxToRem(80);
  position: relative;

  @media #{$mq-large} {
    margin-top: pxToRem(20);
    margin-bottom: pxToRem(20);
  }
}

.o-aside-hidden {
  display: none;
}

.t-bg--primary {
  background-color: #001b50 !important;
}

.t-bg--white-three {
  background-color: #f4f4f4 !important;
}

.t-bg--grey-light {
  background-color: #f5f5f5 !important;
}

.u-align-right {
  text-align: right !important;
}

.u-no-margin-top {
  margin-top: 0 !important;
}
