/* Module: S */
@import '../bower_components/slick-carousel/slick/slick.scss';

// Placeholders
%sq-btn-reset {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  border: pxToRem(2) solid transparent;
  border-radius: pxToRem(1);
  cursor: pointer;
  display: inline-block;
  line-height: normal;
  text-align: center;
  text-decoration: none;
}

// Styles
.c-carousel {
  position: relative;
  width: 100%;
}

.c-carousel__list {
  @extend %list-reset;
}

.c-carousel__img-wrapper {
  display: block;
  width: 100%;
}

// Slick slider
.slick-slide {
  outline: none;
}
