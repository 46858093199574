.p-nav {
  align-content: flex-start;
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: pxToRem(15) 0;

  @media #{$mq-large} {
    padding: pxToRem(25) 0 pxToRem(0);
    position: relative;
  }
}

.p-logo__img {
  width: pxToRem(80);

  @media #{$mq-large} {
    min-width: pxToRem(125);
    width: pxToRem(320);
  }
}

.p-nav__section {
  background-color: $primary-color;
  color: $pure-white;
  height: 100%;
  left: 100%;
  padding: pxToRem(24) pxToRem(20);
  position: absolute;
  top: 0;
  width: 100%;

  @media #{$mq-large} {
    background-color: transparent;
    left: 0;
    padding-bottom: 0;
    padding-right: 0;
    padding-top: pxToRem(10);
    position: static;
  }

  .is-menu-open & {
    max-height: 100vh;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
}

.p-nav__close-btn {
  display: block;
  height: pxToRem(16);
  position: absolute;
  right: pxToRem(20);
  transition: transform .3s ease;
  width: pxToRem(16);

  @media #{$mq-large} {
    display: none;
  }

  &:hover {
    transform: rotate(90deg);
  }

  &::before,
  &::after {
    background-color: $pure-white;
    content: '';
    height: 100%;
    left: 50%;
    margin-left: pxToRem(-1);
    position: absolute;
    width: pxToRem(2);
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
}

.p-nav__mobile-container {
  display: flex;

  a {
    display: block;
  }
}

.p-nav__mobile {
  @media #{$mq-large} {
    display: none;
  }
}

.p-nav__nav-btn {
  margin-left: pxToRem(26);

  svg {
    margin-top: pxToRem(-3);
  }
}

.p-nav__search {
  margin-bottom: pxToRem(10);
  width: pxToRem(235);

  @media #{$mq-medium} {
    width: pxToRem(580);
  }

  @media #{$mq-large} {
    float: right;
    margin-bottom: 0;
  }
}

.p-footer__search-wrapper {
  .p-nav__search & {
    @media #{$mq-large} {
      border: pxToRem(1) solid $white-four;
      width: pxToRem(370) !important;
    }
  }
}

.p-nav__menu-list {
  @extend %list-reset;

  @media #{$mq-large} {
    align-content: flex-start;
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    width: 100%;
  }
}

.p-nav__menu-item {
  margin-bottom: 0;

  @media #{$mq-large} {
    position: static;

    &:hover > .p-nav__submenu {
      opacity: 1;
      transform: translateY(0);
      visibility: visible;
    }
  }
}

.p-nav__menu-link {
  border-bottom: pxToRem(1) solid #12336c;
  display: block;
  font-size: pxToRem(18);
  padding: pxToRem(17) 0;
  position: relative;

  @media #{$mq-large} {
    border-bottom: pxToRem(6) solid transparent;
    color: $primary-color !important;
    font-family: $font-helv-bold;
    font-size: pxToRem(16);
    // font-weight: 600;
    margin-left: pxToRem(30);
    padding: pxToRem(30) 0;
    text-transform: uppercase;
    transition: border .3s ease;
  }

  &:hover,
  &.is-submenu-open {
    text-decoration: none;

    @media #{$mq-large} {
      border-color: $primary-color;
    }
  }

  .is-menu-current & {
    border: 0;

    @media #{$mq-large} {
      border-bottom: pxToRem(6) solid $primary-color;
    }
  }

  .is-windows & {
    font-weight: 600;
  }

  .is-chrome.is-windows & {
    font-family: "Helvetica Neue Medium", sans-serif;
    font-weight: 600;
  }

  .is-windows.is-firefox & {
    font-family: "Arial", sans-serif; 
    // font-weight: 500;
  }
}

.p-nav__submenu-wrapper {
  @media #{$mq-large} {
    align-content: flex-start;
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
}

.p-nav__submenu-outer {
  @media #{$mq-large} {
    align-content: flex-start;
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    min-width: 75%;

    &:first-of-type {
      min-width: 25%;
      padding-right: pxToRem(85);
    }

    & a:hover {
      text-decoration: underline;
    }
  }

  li {
    margin-bottom: pxToRem(15);
  }

  a {
    font-family: $font-helv-medium;
    font-size: pxToRem(18);
    line-height: pxToRem(33);
  }
}

.p-nav__submenu-inner {
  @media #{$mq-large} {
    margin-bottom: pxToRem(26);
    padding-right: pxToRem(40);
    width: 33.3333%;
  }

  ul {
    a {
      color: $bluey-grey;
      font-size: pxToRem(14);
      line-height: pxToRem(21);
    }
  }
}

.p-nav__submenu-heading {
  display: inline-block;
  margin-bottom: pxToRem(10);
}

.p-nav__submenu-inner ul a.p-nav__submenu-all {
  display: inline-block;
  font-size: pxToRem(12) !important;
  //margin-top: pxToRem(9);
  color: #ffffff;
}

.p-nav__submenu {
  display: none;
  padding: pxToRem(50) 0 pxToRem(30);

  @media #{$mq-large} {
    background-color: $primary-color;
    display: block;
    left: 0;
    opacity: 0;
    position: absolute;
    transform: translateY(.625rem);
    transition: all .3s ease;
    visibility: hidden;
    width: 100%;
    z-index: 10;
  }

  &::before,
  &::after {
    background-color: $primary-color;
    content: '';
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &::before {
    left: 100%;
  }

  &::after {
    right: 100%;
  }

  &:hover,
  &.is-submenu-open {
    opacity: 1;
    transform: translateY(0);
    visibility: visible;
  }

  ul {
    @extend %list-reset;
  }
}

.p-nav__submenu--mobile {
  display: none;
  margin-bottom: pxToRem(10);
  padding-left: pxToRem(20);

  @media #{$mq-large} {
    display: none !important;
  }

  li {
    margin-bottom: pxToRem(15);
  }

  a {
    color: #94b9ff;
  }
}

.p-nav__menu-arrow {
  display: inline-block;
  position: absolute;
  right: 0;
  top: pxToRem(17);
  transition: transform .3s cubic-bezier(.77, 0, .175, 1);

  @media #{$mq-large} {
    display: none;
  }

  .is-menu-current & {
    transform: rotate(180deg);
  }
}
