/* Module: S */
//MQ
// Page level breakpoints
// $mq-small: 'screen and (max-width: 56.1875rem)'; //899
// $mq-medium: 'screen and (min-width: 56.25rem)'; //900px
// $mq-large: 'screen and (min-width: 73.125rem)'; //1170px
// // Component level breakpoints
// $mq-700: 'screen and (min-width: 43.75rem)';
// $mq-1190: 'screen and (min-width: 74.375rem)';

// Transition
$transition-default: .5s cubic-bezier(.23, 1, .32, 1);

// REM font sizing with pixel fall back
// This technique will allow external font sizes to be set for html and respected
// if REMs are supported (everything but IE8 and older).
@mixin sq-font-size($sizeValue: 1) {
    @include sq-rem-attr(font-size, $sizeValue);
}

// Placeholders
%btn-reset {
  background-color: transparent;
  border: 0;
  color: $pure-black;
  display: inline-block;
  font-weight: $font-bold;
  padding: 0;
  transition: background-color $transition-default;

  &:hover {
    text-decoration: none;
  }
}

.c-icon {
  fill: currentColor;
  height: 1em;
  overflow: hidden;
  vertical-align: -.15em;
  width: 1em;

  @media #{$mq-large} {
    padding-bottom: pxToRem(10);
  }
}

.c-section-nav {
  background-color: $white;
  position: absolute;
  width: 100%;

  @media #{$mq-medium} {
    padding-bottom: pxToRem(1);
  }

  @media #{$mq-large} {
    position: relative;
  }
}

.c-section-nav__title {
  @include sq-font-size(1.25);
  background-color: $white-two;
  color: $navy-blue;
  //display: none;
  display: block;
  font-weight: $font-bold;
  position: relative;
  transition: background-color .3s ease;
  width: 100%;

  @media #{$mq-large} {
    display: block;
  }

  &:hover {
    background-color: darken($white-two, 10);
    text-decoration: none;
  }

  a:first-child {
    display: block;
    padding: pxToRem(22) pxToRem(22);
  }

  a:last-child {
    border: pxToRem(2) solid $white;
    margin-top: pxToRem(-12);
    padding: 0 pxToRem(5);
    position: absolute;
    // right: pxToRem(15);
    right: pxToRem(0);
    // top: 50%;

    padding: 20px 25px;
    top: 22%;
    transform: rotate(0deg);

    @media #{$mq-large} {
      display: none;
    }
  }
}

h2.c-section-nav__title {
  font-family: $base-font-family;
  margin: 0;
  padding: pxToRem(22);
  line-height: pxToRem(24);

  .is-windows.is-firefox & {
    font-family: "Arial", sans-serif;
  }
}

.c-section-nav__heading {
  position: relative;
  margin: 0;
  background-color: $white;

}

.c-section-nav__btn {
  background-color: transparent;
  border: 0;
  color: $pure-black;
  display: inline-block;
  text-align: left;
  font-weight: $font-medium;
  transition: background-color $transition-default;
  padding: 0;
}

.c-section-nav__btn{
  @include sq-font-size(1);
  border-left: pxToRem(5) $white solid;
  color: $navy-blue;
  font-weight: $font-medium;
  line-height: pxToRem(24);
  padding: pxToRem(15) pxToRem(54) pxToRem(15) pxToRem(20);
  //margin: pxToRem(12) 0;
  position: relative;
  transition: all .3s ease;

  .c-section-nav.is-expanded & {
    padding: pxToRem(15) pxToRem(20);
  }

  @media #{$mq-large} {
    padding: pxToRem(15) pxToRem(20);
  }

  .is-current &,
  &:hover {
    border-left: pxToRem(5) solid rgba(1, 21, 73, .4);
    color: rgba(1, 21, 73, .4);
    text-decoration: none;
  }
}

.c-section-nav__icon-wrapper {
  display: none;

  @media #{$mq-large} {
    display: none;
  }
}

.c-section-nav__icon {
  width: pxToRem(14);
  position: absolute;
  right: pxToRem(20);
  top: 50%;
  margin-top: pxToRem(-18);
  transition: transform $transition-default;

  .is-section-nav-active & {
    transform: rotate(180deg);
  }
}

.c-section-nav__content {
  @include sq-font-size(1);
  background-color: $pure-white;
  color: $base-font-colour;
  line-height: pxToRem(24);
  padding: pxToRem(10) pxToRem(20);
}

.js-close-section-nav {
  opacity: 1;
  transform: rotate(0);
  transition: all $transition-default;
  visibility: visible;
  border: none !important;
  .c-section-nav.is-expanded & {
    transform: rotate(180deg);
  }
}

.c-section-nav__item {
  &.is-current {
    position: relative;

    &::after {
      background-image: url('mysource_files/arrow-down-blue.svg');
      content: '';
      height: pxToRem(7);
      margin-top: pxToRem(-3);
      position: absolute;
      right: pxToRem(25);
      top: 50%;
      transform: scale(1.5);
      transition: all $transition-default;
      width: pxToRem(12);

      .c-section-nav.is-expanded & {
        opacity: 0;
        transform: rotate(180deg);
        visibility: hidden;
      }

      @media #{$mq-large} {
        display: none;
      }
    }
  }

  display: none;
  @media #{$mq-large} {
    display: block;
  }
}
